import LayoutPrivate from '@/layouts/private/layout-private.vue';
import mUsers from '@/modules/m-users/m-users.vue';
import userNew from './new/new.vue';
import userEdit from './edit/edit.vue';
// import {eventBus} from '@/main'

export default {
  name: 'users',

  components: {
    'layout-private': LayoutPrivate,
    'm-users': mUsers,
    'user-new': userNew,
    'user-edit': userEdit
  },

  data() {
    return {
      userState: null
    };
  },

  computed: {
    uuid: function() {
      return this.$route.params.uuid;
    },

    currentUserComponent: function() {
      let comp = 'default';

      if(this.userState == 'new' || this.userState == 'edit') {
        comp = 'user-' + this.userState;
      }
      return comp;
    },

    userSelected: function() {
      return this.$store.state.userSelected;
    }
  },

  watch: {
    uuid: function() {
      this.getParam();
    }
  },

  methods: {
    getParam() {
      if(this.$route.params) {
        switch(this.$route.params.uuid) {
          case undefined:
            this.userState = null;
          break;
          case '':
            this.userState = null;
          break;
          case 'new':
            this.userState = 'new';
          break;
          default:
            this.userState = 'edit';
          break;
        }
      }else{
        this.userState = null;
      }
    }
  },

  mounted() {
    this.getParam();
  }
};
