import oBlock from '@/objects/o-block/o-block.vue';

export default {
  name: 'oModal',

  components: {
    'o-block': oBlock
  },

  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
};
