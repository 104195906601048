import EventBus from '@/config/EventBus';
import ApiHelper from '@/helpers/ApiHelper';
import ErrorHelper from "@/helpers/ErrorHelper";
import StringHelper from "@/helpers/StringHelper";
import Notification from "@/utils/Notification";
import CONST from "@/utils/Constants"; // eslint-disable-line no-unused-
import oButton from "@/objects/o-button/o-button.vue";
import oInput from "@/objects/o-input/o-input.vue";
import oRole from "@/objects/o-role/o-role.vue";
import oSelect from "@/objects/o-select/o-select.vue";
import oLabel from "@/objects/o-label/o-label.vue";
import oModal from '@/objects/o-modal/o-modal.vue';
import oValidationNotification from '@/objects/o-validation-notification/o-validation-notification.vue';

export default {
    name: 'mUserEdit',

    components: {
        "o-button": oButton,
        "o-input": oInput,
        "o-select": oSelect,
        "o-label": oLabel,
        "o-modal": oModal,
        "o-role": oRole,
        "o-validation-notification": oValidationNotification
    },

    data() {
      return {
        showModalDelete: false,
        user: {
          name: null,
          email: null,
          locked: false,
          roles: []
        },
        currentUser: {
          name: null,
          email: null
        },
        roles: CONST.VALUE.ROLES,
        role: null
      };
    },

    methods: {
      getUser() {
        ApiHelper.get(ApiHelper.parseUrlWithParameters(CONST.API.ROUTE.USERS.DETAIL, [{uuid: this.$route.params.uuid}]), {headerType: 'basic'})
          .then(response => {
            this.user = response.data;
            this.currentUser.name = response.data.name;
            this.currentUser.email = response.data.email;

            if(this.user.email == CONST.USER.LOCKED.EMAIL) {
              this.user.locked = true;
            }else{
              this.user.locked = false;
            }
          })
          .catch(error => {
              ErrorHelper.catch(error);
          })
          .finally(() => {
              this.loading = false;
          });
      },

      deleteUser() {
        if(this.user.id != CONST.USER.NON_DELETABLE) {
          ApiHelper.delete(ApiHelper.parseUrlWithParameters(CONST.API.ROUTE.USERS.DELETE, [{uuid: this.$route.params.uuid}]), {headerType: 'basic'})
            .then(() => {
                this.$router.push(CONST.ROUTE.USERS.OVERVIEW);
                EventBus.$emit(CONST.EVENTS.USER.DELETED);
            })
            .catch(error => {
                ErrorHelper.catch(error);
            })
            .finally(() => {
              this.loading = false;
              this.showModalDelete = false;
              this.$store.commit('userSelected', false);

              Notification.add(
                this.$t("notifications.success.user-deleted"),
                Notification.TYPE.SUCCESS,
                5000
              );
          });
        }
      },

      saveUser() {
        let user = {};

        if(this.currentUser.name != this.user.name) {
          user.name = this.user.name;
        }
        if(this.currentUser.email != this.user.email) {
          user.email = this.user.email;
        }

        if(user.name || user.email) {
          ApiHelper.post(ApiHelper.parseUrlWithParameters(CONST.API.ROUTE.USERS.DETAIL, [{uuid: this.$route.params.uuid}]), user, {headerType: 'basic'})
            .then(response => {
              this.user = response.data;

              if(this.user.email == CONST.USER.LOCKED.EMAIL) {
                this.user.locked = true;
              }else{
                this.user.locked = false;
              }

              EventBus.$emit(CONST.EVENTS.USER.UPDATED);

              Notification.add(
                this.$t("notifications.success.user-updated"),
                Notification.TYPE.SUCCESS,
                5000
              );
            })
            .catch(error => {
              let errorObj = error.response.data.error;

              if(errorObj.code == CONST.API.EXCEPTION.VALIDATION_ERROR) {
                for (const [key, message] of Object.entries(errorObj.message)) {
                  this.$refs[key].active = true;
                  this.$refs[key].message = message[0];
                  this.$refs[key].type = errorObj.type;
                }
              }else{
                ErrorHelper.catch(error);
              }
            })
            .finally(() => {
                this.loading = false;
            });
        }

        if(this.role) {
          this.addRole();
        }
      },

      addRole() {
        let role = {
          role: this.role
        };

        ApiHelper.post(ApiHelper.parseUrlWithParameters(CONST.API.ROUTE.USERS.ADD_ROLE, [{uuid: this.user.id}]), role, {headerType: 'basic'})
        .then(response => {
          this.role = null;
          this.user = response.data;

          EventBus.$emit(CONST.EVENTS.USER.UPDATED);

          Notification.add(
            this.$t("notifications.success.user-role-added"),
            Notification.TYPE.SUCCESS,
            5000
          );
        });
      },

      deleteRole(_role) {
        let role = {
          role: _role
        };

        ApiHelper.post(ApiHelper.parseUrlWithParameters(CONST.API.ROUTE.USERS.DELETE_ROLE, [{uuid: this.user.id}]), role, {headerType: 'basic'})
        .then(response => {
          this.user = response.data;

          EventBus.$emit(CONST.EVENTS.USER.UPDATED);

          Notification.add(
            this.$t("notifications.success.user-role-deleted"),
            Notification.TYPE.SUCCESS,
            5000
          );
        });
      },

      toggleModalDelete: function(){
        this.showModalDelete = !this.showModalDelete;
      },

      hideModalDelete: function() {
        this.showModalDelete = false;
      },

      clearValidations() {
        if(this.$refs['email']) {
          this.$refs['email'].active = false;
        }
        if(this.$refs['password']) {
          this.$refs['password'].active = false;
        }
      },

      onClickBack: function() {
        this.$store.commit('userSelected', false);
      }
    },

    watch: {
      uuid: function() {
        this.getUser();
        this.clearValidations();
      }
    },

    computed: {
      uuid: function() {
        return this.$route.params.uuid;
      },

      nameIsValid() {
        let valid = false;

        if(this.user) {
          if(this.user.name){
            valid = StringHelper.isNotEmpty(this.user.name);
          }
        }

        return valid;
      },

      emailIsValid() {
        let valid = false;

        if(this.user) {
          if(this.user.email){
            valid = StringHelper.isEmail(this.user.email);
          }
        }

        return valid;
      },

      roleIsValid() {
        let valid = false;

        if(this.user) {
          if(this.user.role){
            if(this.user.role.length > 0) {
              valid = true;
            }
          }
        }
        return valid;
      },

      userIsValid() {
        return (this.nameIsValid && this.emailIsValid);
      }
    },

    mounted(){
      this.getUser();
    }
};
