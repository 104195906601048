import EventBus from '@/config/EventBus';
import ApiHelper from '@/helpers/ApiHelper';
import ErrorHelper from "@/helpers/ErrorHelper";
import StringHelper from "@/helpers/StringHelper";
import Notification from "@/utils/Notification";
import CONST from "@/utils/Constants"; // eslint-disable-line no-unused-
import oButton from "@/objects/o-button/o-button.vue";
import oInput from "@/objects/o-input/o-input.vue";
import oSelect from "@/objects/o-select/o-select.vue";
import oLabel from "@/objects/o-label/o-label.vue";
import oValidationNotification from '@/objects/o-validation-notification/o-validation-notification.vue';

export default {
    name: 'mUserNew',

    components: {
        "o-button": oButton,
        "o-input": oInput,
        "o-select": oSelect,
        "o-label": oLabel,
        "o-validation-notification": oValidationNotification
    },

    data() {
      return {
        user: {
          name: null,
          email: null,
          password: null,
          password_confirmation: null,
          roles: null
        },
        roles: CONST.VALUE.ROLES
      };
    },

    methods: {
      clearValidations() {
        if(this.$refs['email']) {
          this.$refs['email'].active = false;
        }
        if(this.$refs['password']) {
          this.$refs['password'].active = false;
        }
      },

      saveUser() {
        let user = {
          name: this.user.name,
          email: this.user.email,
          password: this.user.password,
          password_confirmation: this.user.password_confirmation
        };

        ApiHelper.post(CONST.API.ROUTE.USERS.NEW, user, {headerType: 'basic'})
              .then(response => {
                this.user = response.data;

                EventBus.$emit(CONST.EVENTS.USER.ADDED);
                this.$router.push(ApiHelper.parseUrlWithParameters(CONST.ROUTE.USERS.EDIT, [{uuid: this.user.id}]));

                Notification.add(
                  this.$t("notifications.success.user-added"),
                  Notification.TYPE.SUCCESS,
                  5000
                );
              })
              .catch(error => {
                let errorObj = error.response.data.error;

                if(errorObj.code == CONST.API.EXCEPTION.VALIDATION_ERROR) {
                  for (const [key, message] of Object.entries(errorObj.message)) {
                    this.$refs[key].active = true;
                    this.$refs[key].message = message[0];
                    this.$refs[key].type = errorObj.type;
                  }
                }else{
                  ErrorHelper.catch(error);
                }
              })
              .finally(() => {
                  this.loading = false;
              });
      },

      onClickBack: function() {
        this.$store.commit('userSelected', false);
      }
    },

    computed: {
      nameIsValid() {
        let valid = false;

        if(this.user) {
          if(this.user.name){
            valid = StringHelper.isNotEmpty(this.user.name);
          }
        }

        return valid;
      },

      emailIsValid() {
        let valid = false;

        if(this.user) {
          if(this.user.email){
            valid = StringHelper.isEmail(this.user.email);
          }
        }

        return valid;
      },

      roleIsValid() {
        let valid = false;

        if(this.user) {
          if(this.user.role){
            if(this.user.role.length > 0) {
              valid = true;
            }
          }
        }
        return valid;
      },

      userIsValid() {
        return (this.nameIsValid && this.emailIsValid);
      }
    },

    mounted() {
      this.$store.commit('userSelected', true);
    }
};
