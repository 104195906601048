import { render, staticRenderFns } from "./o-role.vue?vue&type=template&id=62cac7c7&scoped=true&"
import script from "./o-role.js?vue&type=script&lang=js&"
export * from "./o-role.js?vue&type=script&lang=js&"
import style0 from "./o-role.scss?vue&type=style&index=0&id=62cac7c7&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62cac7c7",
  null
  
)

export default component.exports